<template>
  <div class="d-flex align-items-center ">
    <StaticLock @conditionPass="handleConditionPass"></StaticLock>
    <div class="langtoggle">
      <LanguageSwitch></LanguageSwitch>
    </div>
    <div v-if="coverImage !== ''" class="header-cover" :style="{
      'background-image': `url('${coverImage}')`,
      width: '100%',
      'background-size': 'cover',
      'background-position': 'center center',
    }"></div>
    <CCard class="p-3" :style="{ top: cardTop }">
      <CCardBody class="text-center">
        <div style="display: flex; justify-content: center;">
          <div class="logoImg" :style="{
            'background-image': `url('${logo}'), url('${noImg}')`,
            'background-size': 'cover',
            'background-position': 'center center',
          }"></div>
        </div>
        <h2 class="font-weight-bold mt-3">
          {{ shopName }}
        </h2>
        <h6 class="text-grey mb-4">{{ branchName }} </h6>
        <span class="h5 font-weight-normal">
          <i class="fi fi-rr-utensils"></i>
          {{ tableName }} &emsp; | &emsp;
          <i class="fi fi-rr-user"></i>
          {{ pax }} {{ $t('guest') }}
        </span><br>
        <CButton :disabled="loading" class="mt-5 btn-startorder p-3 " style=" font-size: 20px;color: white;" block
          @click="openTable">
          <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" /> &nbsp;
          {{ $t('startOrder') }}
        </CButton>
        <div class="row">
          <div class="col-6">
            <CButton :disabled="loading" class="mt-3 btn-square-home" @click="goToCart">
              <i class="fi fi-rr-shopping-basket" style="font-size: 30px;"></i>
              <br>
              {{ $t('orderItemNav') }}
            </CButton>
          </div>
          <div class="col-6">
            <CButton :disabled="loading" class="mt-3 btn-square-home " @click="() => { callStaffModal = true }">
              <img src="@/assets/icons/bell.png" class="img-fluid pb-2" style="width: 34px;margin-bottom: 6px;">
              <br>
              {{ $t('callStaff') }}
            </CButton>
          </div>
        </div>
        <small hidden>{{ timestamp }}</small>
        <div class="mt-5 mb-5">
          <span>
            Powered by
            <img src="/logo.png" width="30%" alt="" class="img-fluid ml-1" />
          </span>
        </div>
      </CCardBody>
    </CCard>
    <call-staff v-bind:callStaffModal="callStaffModal" @update-status="callStaffModal = false"></call-staff>
    <div class="footer-home"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import pos from '@/services/pos'
import qs from 'qs'
import moment from 'moment'
import '@/util/menu.css'
import CallStaff from '../dine-in/CallStaff'
import LanguageSwitch from '@/components/AppLanguageSwitch'
import StaticLock from '../dine-in/StaticLock.vue'
import axios from 'axios'

export default {
  components: {
    CallStaff,
    LanguageSwitch,
    StaticLock
  },
  data() {
    return {
      table: {},
      receiptId: '',
      receiptObjectId: '',
      callStaffModal: false,
      showDropdown: false,
      coverImage: '',
      cartLists: [],
      loading: false,
      screenWidth: window.innerWidth,
      qrType: '',
      orderShopSetting: {},
      tableNameText: '',
      paxText: '',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    cardTop() {
      return `${this.screenWidth / 5}px`;
    },
    uid() {
      return localStorage.getItem('uid') || ''
    },
    shopObjectId() {
      return localStorage.getItem('shopObjectId') || ''
    },
    tableObjectId() {
      return localStorage.getItem('tableObjectId') || ''
    },
    timestamp() {
      let time = localStorage.getItem('timestamp')
      return time
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        document.title = this.shop?.shopName + ' by SilomPOS'
        localStorage.setItem('shopName', this.shop.shopName)
        return this.shop.shopName
      } else {
        return ''
      }
    },
    logo() {
      const shop = this.shop || {}
      if (shop.remoteImagePath) {
        return shop.remoteImagePath
      } else {
        return 'https://cdn-icons-png.flaticon.com/512/4711/4711299.png'
      }
    },
    branchName() {
      if (this.shop) {
        return this.shop.branchName
      } else {
        return ''
      }
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    tableName() {
      const table = this.table || JSON.parse(localStorage.getItem('table')) || {}
      return table?.name || this.table?.name || this.tableNameText
    },
    pax() {
      const pax = localStorage.getItem('pax')
      return this.paxText || pax
    }
  },
  beforeCreate() {
    // this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    // if (this.cartLists.length == 0) {
    //   sessionStorage.clear()
    // }
  },
  async created() {
    await this.validateOTP()
    // this.getOrderShopSetting()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    handleConditionPass(status) {
      if (status) {
        this.getTableData()
        let timestamp = localStorage.getItem('timestamp') || 'undefined'
        if (timestamp == 'undefined') {
          let time = new Date().getTime()
          localStorage.setItem('timestamp', time)
          this.$router.replace({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              time: time,
            },
          });
        }
      }
    },
    getName() {
      if (!localStorage.getItem('name')) {
        try {
          pos({
            method: 'get',
            url: '/auth/v1.0/session/tempname',
            params: { tableObjectId: this.tableObjectId }
          }).then((res) => {
            let name = res.data?.data?.name || 'A'
            localStorage.setItem('name', name)
          })
        } catch (error) {
          throw new Error(error)
        }
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async getOrderShopSetting(tableData = null) {
      this.loading = true
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      let params = {
        shopObjectId: shopObjectId,
      }
      try {
        await poscrm({
          method: 'get',
          url: '/api/v2.1/' + uid + '/OrderShopSetting',
          params: params,
        }).then((res) => {
          this.orderShopSetting = res.data?.data[0] || {}
          this.coverImage = res.data?.data?.[0]?.remoteCoverImagePath || '';
          console.log('QR Type :', res.data?.data[0]?.qrType)
          this.qrType = res.data?.data[0]?.qrType || 'DYNAMICS'
          localStorage.setItem('qrType', this.qrType)

          let tableLayout = res.data?.data[0]?.tableLayout?.enabled || false
          localStorage.setItem('tableLayout', tableLayout)

          if (this.qrType == 'STATIC') {
            const otp = this.$route.params.otp
            if (otp && tableData !== null) {
              const uid = tableData?.data?.data?.uid || "";
              const shopObjectId = tableData?.data?.data?.shopObjectId || "";
              const tableObjectId = tableData?.data?.data?.tableObjectId || "";
              const pax = tableData?.data?.data?.pax || localStorage.getItem('pax') || "";
              window.location.href = `/dine-in/home?uid=${uid}&shopObjectId=${shopObjectId}&tableObjectId=${tableObjectId}&pax=${pax}`
            }
          } else {
            const otp = this.$route.params.otp
            const table = this.$route.query.tableObjectId
            if (!otp && table) {
              this.$router.push(`/expired?errorCode=4`)
            } else if (!otp) {
              this.$router.push(`/expired?errorCode=2`)
            } else {
              this.getTableStatusFromFB()
            }
          }
          const themeColor = res.data?.data[0]?.themeColor || '#29B46B'
          $(':root').css('--bg-color-theme', themeColor);
        })
        this.loading = false
      } catch (error) {
        this.loading = false
        throw new Error(error)
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.showDropdown = false
      this.trackButtonClick('home/change_lang_to_' + locale)
    },

    goToCart() {
      this.trackButtonClick('home/mycart')
      window.location.href = "/dine-in/mycart"
    },

    async openTable() {
      this.trackButtonClick('home/open_table')
      this.loading = true
      const uid = this.uid

      let data = {
        shopObjectId: this.shopObjectId,
        tableObjectId: this.table.objectId,
        status: 1,
        receiptId: this.receiptId,
        receiptObjectId: this.receiptObjectId,
      }

      try {
        await poscrm({
          url: '/api/v1.1/' + uid + '/Shop/updatetablestatus',
          data: qs.stringify(data),
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((res) => {
          window.location.href = "/dine-in/menulists"
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async getTableData() {
      let params = {
        shopObjectId: this.shopObjectId,
        objectId: this.tableObjectId,
      }
      try {
        await poscrm
          .get('/api/v1.1/' + this.uid + '/Shop/gettablebyobjectid/fbdata', {
            params: params,
          })
          .then((res) => {
            if (res.data?.error?.code == 0) {
              let response = {}
              response = res.data?.data
              let tabledata = {
                objectId: response.objectId,
                id: response.id,
                name: response.name,
                pax: response?.lockedBy?.pax || 1
              }
              localStorage.setItem('pax', tabledata.pax)
              this.paxText = tabledata.pax
              this.table = tabledata
              localStorage.setItem('table', JSON.stringify(tabledata))
            }
          })
      } catch (error) {
        throw new Error(error)
      }
    },
    async getTableStatusFromFB() {
      let params = {
        shopObjectId: this.shopObjectId,
        objectId: this.tableObjectId,
      }

      try {
        await poscrm
          .get('/api/v1.1/' + this.uid + '/Shop/gettablebyobjectid/fbdata', {
            params: params,
          })
          .then((res) => {
            if (res.data?.error?.code == 0) {
              let response = {}
              response = res.data?.data || {}
              localStorage.setItem('orderStart', response.orderStart)
              localStorage.setItem('orderEnd', response.orderEnd)
            } else {
              alert('ติดต่อพนักงานของเราเพื่อขอความช่วยเหลือ')
              // sessionStorage.clear()
              // localStorage.clear()
              // window.location.href = '/texp?errorCode=-1001'
            }
          })
      } catch (error) {
        throw new Error(error)
      }
    },

    async validateOTP() {
      this.loading = true
      try {
        const otp = this.$route.params.otp
        if (!otp) {
          this.setUpLocalStorage(null)
          await this.getOrderShopSetting()
          return;
        }

        // ✅ Call API with the correct request format

        const response = await poscrm
          .post('/api/v1.0/selforder/validateotp', {
            otp: otp,
          })

        if (response && response.data?.error?.code == 0) {
          this.setUpLocalStorage(response)
          await this.getOrderShopSetting(response)
        } else {
          console.error("❌ Unexpected response format:", response);
          const errorCode = response?.data?.error?.code
          this.$router.push(`/expired?errorCode=${errorCode}`)
        }

      } catch (error) {
        console.error("❌ API Call Failed:", error);
      }
    },
    setUpLocalStorage(response) {
      if (this.qrType == "DYNAMIC") {
        localStorage.clear()
      }
      // ✅ Check if response is null; use query params as fallback
      const table = response?.data?.data?.table || {};
      const uid = response?.data?.data?.uid || this.$route.query.uid || "";
      const shopObjectId = response?.data?.data?.shopObjectId || this.$route.query.shopObjectId || "";
      const tableObjectId = response?.data?.data?.tableObjectId || this.$route.query.tableObjectId || "";
      const pax = response?.data?.data?.pax || localStorage.getItem('pax') || "";
      const otp = response?.data?.data?.otp || this.$route.query.otp || null;

      this.tableNameText = table?.name || ''
      this.paxText = pax || ''


      // ✅ Save extracted data to localStorage
      localStorage.setItem("table", JSON.stringify(table));
      localStorage.setItem("uid", uid);
      localStorage.setItem("shopObjectId", shopObjectId);
      localStorage.setItem("tableObjectId", tableObjectId);
      localStorage.setItem("pax", pax);
      if (otp) {
        localStorage.setItem("otp", otp);
      }
      this.$store.dispatch('getShopLineOA');
      this.getName();
      this.loading = false
    },

  },
}
</script>

<style>
.logoImg {
  background-color: rgb(255, 255, 255);
  width: 50%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.header-cover {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  aspect-ratio: 2/1;

  /* Set height to 50% of width */
}

.footer-home {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  aspect-ratio: 2/1;
  background-color: rgb(255, 255, 255);
}

.langtoggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  padding: 20px;
}

.card {
  background-color: unset;
  border: 0cap;
  overflow-y: scroll;
  z-index: 1000;
}
</style>
